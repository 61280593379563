import React, { Component } from 'react';
import { startCase, lowerCase, upperCase, trim } from 'lodash';
import {
  Layout,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Avatar,
  Spin,
  Tooltip,
  Divider,
  Modal,
  Result,
} from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  BarsOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Menus } from './Menus';

import SideMenu from './SideMenu';
import ContentBody from './ContentBody';

import {
  GET_GROWER_PROFILE,
  SA_PROFILE,
  APP_HOME,
  SA_DASHBOARD,
  SR_DASHBOARD,
  DASHBOARD,
  ADMIN_BULLETIN,
  CSA_DASHBOARD,
  SR_PROFILE,
  CSA_PROFILE,
  APP_GROWER_LOGIN,
} from '../constants/RouteConstants';
import { USER_TYPES } from '../constants/ApplicationConstants';
//import { applicationSteps } from '../grower/profileAndApplication/utilities';
import {
  getUserTypeFromUrl,
  dataLayer,
  isGrowerInactive,
} from '../utilities/UtilityFunctions';
import FinancialYearSelector from '../shared/components/financialYearSelector/FinancialYearSelectorContainer';
import logo from '../../client/images/logo-horizontal.png';
import notification from '../../client/images/notifications.svg';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import IdleTimer from 'react-idle-timer';
import IdleTimeOutModal from './IdleTimeOutModal';
import NoticeModal from './NoticeModal';
import './landing.less';
import { getEnvironmentValue, SALES_YEAR_CONFIG } from '../../envConfig';
import CookieConsent from '../common/CookieConsent/CookieConsent';
import CookieInfo from '../common/CookieConsent/CookieInfo';
import { getUserIdleTimeoutDuration } from './user-idle-timeout';
import { addUserIdentification } from '../utilities/smartlook';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.loadUserType = undefined;
    this.showNtc = false;
    this.state = {
      isSiderCollapsed: false,
      isHeaderMenuVisible: false,
      showModal: false,
      showNoticeModal: false,
      agreeClk: false,
      policyClk: false,
      saClicked: false,
      growerClicked: false,
      agreementLinkClicked: false,
      policyLinkClicked: false,
      userLoggedIn: false,
      isTimedOut: false,
      currentUsertype: 'user',
      showCookie: false,
      smallScreen: window.matchMedia('(max-width: 500px)').matches,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.openCookiePolicy = this.openCookiePolicy.bind(this);
    this.closeCookiePolicy = this.closeCookiePolicy.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNoticeClose = this.handleNoticeClose.bind(this);
    this.handlepolicyClick = this.handlepolicyClick.bind(this);
    this.handleagrrementClick = this.handleagrrementClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleAgreeLink = this.handleAgreeLink.bind(this);
    this.handlePolicyLink = this.handlePolicyLink.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const userTimedOut = this.state.isTimedOut;
    if (userTimedOut) {
      this.props.logoutUser();
      this.props.history.push(APP_GROWER_LOGIN);
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleNoticeClose() {
    this.setState({ showModal: false });
    // this.setState({ showNoticeModal: false });
    this.showNtc = false;
    const { updateNotificationStatus } = this.props;
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    userDetails['is_agreements_agreed'] = true;
    this.props.aggreeClick();

    if (userDetails.userType === 'SEED_ADVISOR') {
      this.setState({ saClicked: true });
    } else if (userDetails.userType === 'GROWER') {
      this.setState({ growerClicked: true });
    }
    if (userDetails.userType === 'GROWER' && userDetails.step !== undefined) {
      delete userDetails.step;
    }
    sessionStorage.setItem('userInfo', JSON.stringify(userDetails));
    this.setState({ agreeClk: false });
    this.setState({ policyClk: false });

    updateNotificationStatus(userDetails);
    setTimeout(() => {
      window.location.reload();
      // console.log('Delayed for 1 second reload.');
    }, '1000');
  }

  handleAgreeLink() {
    this.setState({ agreementLinkClicked: true });
  }

  handlepolicyClick() {
    this.setState({ policyClk: true });
    Modal.info({
      title: 'Privacy Policy',
      width: '80%', // Adjust the width as needed
      content: (
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: `
           
<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta name="Description" content="By using this Site you are accepting the practices described in this Privacy Statement on how Syngenta collects, stores, uses and processes data you provide." />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    <title>Online Privacy Policy</title>
    <!--  <link rel="stylesheet" type="text/css" href="../css/reset.css" />-->
    <link rel="stylesheet" type="text/css" href="/css/bootstrap.css" />
    <link rel="stylesheet" type="text/css" href="/css/hf2017.css" />
    <!--    <link rel="stylesheet" type="text/css" href="/css/style.css" />-->
    <link rel="stylesheet" type="text/css" href="/css/styleHome_new.css" />
    <style>
        .table, th, td
        {
            border: 0.5px solid black;
            padding: 10px 10px;
            width: 20%;
        }
        th
        {
           color: white;
           background-color: black;
           border: 1px solid white;

        }
        #privacy-laws
        {
            border: 3px solid black;
            padding: 30px 30px;
        }

        html {
            scroll-padding-top: 120px;
        }
    </style>
</head>
<body class="no-resp" style="overflow: visible">
    <header id="m-menu">
        <div class="nav-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 col-md-push-10">
                    </div>
                    <div class="col-md-10 col-md-pull-2">
                        <div class="hidden-xs hidden-sm" style="height:80px"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="sectionTitle">
                        <h2>
                            Privacy Statement
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12" id="content">
               
                    <p><strong>Introduction.</strong>This site is managed by Syngenta Crop Protection, LLC (Syngenta) - the “data controller”. Your privacy is important to us. Syngenta has therefore developed this privacy statement (this “Statement” or “Privacy Statement”) to inform you as to how Syngenta collects, stores, uses and processes the personal data and information you provide us through this website or through another forum which references this Privacy Statement (this website and/or such other forum will be referred to as this “site”).</p>

                    <p><strong>Effective Date:</strong> January 1, 2024</p>
                    <p>
                        <strong>Scope of this Statement.</strong>
                        This Statement covers the personal data and information (hereinafter “Personal Information”) we collect through this site. Personal Information is data that identifies you personally, either alone or in combination with other information available to us. Examples of Personal Information include your name, address, email address, tax identification number, if applicable, and the information you provide when you use this site to create an account or a personal profile.
                    </p>

                    <p>
                        <strong>Your Consent.</strong>
                        By using this site you are accepting the practices described in this Statement (including the Cookies Policy available on this site). If you do not agree to this Statement or the Cookies Policy, please do not use this site. We reserve the right to make changes to this Statement or the Cookies Policy at any time. Any changes will be posted in this Statement or the Cookies Policy and material changes will generally apply only to activities and information collected on a going forward basis. We encourage you to regularly review this Statement and the Cookies Policy to make sure you are aware of any changes and how your information may be used.
                    </p>
                    <p><strong>What Personal Data and Information Do We Collect Through This Site?</strong></p>

                    <p>Personal Information refers to information that lets us know the specifics of who you are. Personal Information is requested when you order a service, register for an account and send us feedback. For example, this information might include your name, address (both billing and registered), email address, industry and product details, and credit card or other payment information. Such information may be held on our contacts and administration databases either because of the work we have done with you or are currently engaged in, or because we believe you might be interested in receiving material from us about our business, products, programs, and services. This allows us to manage our relationship with you efficiently, improve this site and the services which we offer to you and to target items of interest we may send to you.</p>
                    <p>Non-personal identifiable information refers to information that is statistical in nature only and that does not, by itself, allow us to identify a specific individual or entity, and which depends upon the visiting you do on this site. For example, this information may include the domain from which you access the internet, the date and time you access this site, and the internet address of the website from which you linked directly to this site. This information is used in an aggregate form to make this site more useful and attractive to its users.</p>

                    <p>
                        <strong>Agreements governing your use of special features and password-protected areas.</strong><br />

                        This site may include special features and password-protected areas. When you sign up to use a special feature or password-protected area, you may be asked to agree to special terms governing your use of the special feature or password-protected area. In such cases, you will be asked to expressly consent to the special terms, for example, by checking a box or clicking on a button marked "I agree." This type of agreement is known as a "click-through" agreement. If any of the terms of the click-through agreement are different than the terms of this Statement, the terms of the click-through agreement will supplement or amend this Statement, but only with respect to the matters governed by the "click-through agreement."
                    </p>

                    <p>
                        <strong>Cookies and similar technologies. </strong>

                        We use "cookies" and other web technologies to collect information and support certain features of our sites. “Cookies” provides us with information about the ways visitors use our sites – which pages they visit in what order, which links they use, and how long they stay on each page.
                    </p>
                    <p>We do this to:</p>                     <p>
                        <ul>
                            <li>analyze and improve the effectiveness of our advertising campaigns;</li>
                            <li>support and enhance the features and functionality of our sites – for example, to save you the trouble of reentering information already in our database or to prompt the settings you established on previous visits to our sites; and</li>
                            <li>personalize your experience when you use our sites.</li>
                        </ul>
                    </p>
                    <p>
                        Generally, the information we collect using these web technologies does not identify you personally.
                    </p>
                    <p>
                        If, however, you have created a user identity, for example, by signing up to use a password-protected area on one of our sites, we may link the information we collect using web technologies to other information that identifies you personally.
                    </p>
                    <p>
                        If you do not wish to receive cookies, you may set your browser to reject cookies or to alert you when a cookie is placed on your computer. You may also delete our cookies as soon as you leave a site. Although you are not required to accept our cookies when you visit our sites, if you set your browser to reject cookies, you will not be able to use all of the features and functionality of our sites. Please see the Cookies Policy on this site for more details.
                    </p>
                    <p>
                        <strong>How does Syngenta use your Personal Information?</strong><br />
                        Generally, we use the Personal Information we collect through this site:<br />
                    </p>
                    <ul>
                        <li>To provide the products, information and services you request from us</li>
                        <li>For security, credit or fraud prevention purposes or any other legal obligation;</li>
                        <li>To contact you with special offers and other information we believe will be of interest to you where you have given us permission to do so.</li>
                        <li>
                            Based upon a legitimate interest we have:
                        </li>
                        <ul>    
                            <li>To provide you with effective customer service, directly or through re-sellers;</li>
                            <li>To provide you with a personalized experience when you use this site;</li>
                            <li>To display personalized advertising when you visit this site;</li>
                            <li>To contact you with information and notices related to your use of this site and our products;</li>
                            <li>To administer programs offered;</li>
                            <li>To confirm and/or update our records; </li>
                            <li>To invite you to participate in surveys and provide feedback to us;</li>
                            <li>To better understand your needs and interests; </li>
                            <li>To improve the content, functionality and usability of this site;</li>
                            <li>To improve our products, programs inventory planning, sales planning and services;</li>
                            <li>To improve our marketing and promotional efforts; and</li>
                        </ul>
                        <li>For any other purpose identified in an applicable privacy notice or other agreement between Syngenta and you.</li>

                    </ul>
                    <p>
                        <strong>
                            How does Syngenta share my Personal Information?  <br />
                        </strong><u>Service providers.</u> We share Personal Information with companies and organizations that perform services on our behalf, for example, companies that provide support services to us (such as credit card processing services, data storage services, and web hosting services) or that help us market our products and services (such as third-party e-mail marketing companies). These third parties are required by contract to use the Personal Information we share with them only to perform services on our behalf and to take commercially reasonable measures to protect the confidentiality and security of your Personal Information.
                    </p>
                    <p><u>Within Syngenta.</u> We may share the Personal Information we collect through this site within Syngenta or with other affiliates of Syngenta for cross-marketing and other purposes. If you do not want us to share your Personal Information with another member of the Syngenta family of businesses you may "opt-out" at any time by completing the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form.</a></p>
                    <p><u>Business transfers.</u> Your Personal Information may be transferred to a company that has acquired the stock or assets of Syngenta, one of its affiliates, or one of our businesses, for example, as the result of a sale, merger, reorganization or liquidation. If such a transfer occurs, the acquiring company's use of your Personal Information will still be subject to this policy and the privacy preferences you have expressed to us.</p>
                    <p><u>Compliance with laws and protection of our rights and the rights of others.</u> We may disclose Personal Information when we, in good faith, believe disclosure is appropriate to comply with the law, a court order or a subpoena. We may also disclose Personal Information to prevent or investigate a possible crime, such as fraud or identity theft; to enforce or apply our online terms of use or other agreements; or to protect our own rights or property or the rights, property or safety of our users or others.</p>
                    <p><u>Aggregate information.</u> We may share aggregated demographic or survey information with third parties, but this information is in anonymous form and does not contain any Personal Information. The aggregate information that we share may include anonymous information that is captured through the use of cookies and other similar tracking technology, as explained in the section entitled “cookies and similar technologies.”</p>

                    <p><u>As described in a privacy notice.</u> We reserve the right to disclose your Personal Information as described in any additional privacy notice posted on the web page (or area) where you provide that information. By providing your Personal Information on that web page you will be consenting to the disclosure of your Personal Information as described in that privacy notice.</p>


                    <p><u>As described in a click-through agreement.</u> We reserve the right to disclose your Personal Information as described in any click-through agreement to which you have agreed.</p>
                    <p>
                        <strong>Your choices</strong><br /><br />
                        <u>In General</u><br />
                        We respect your right to make choices about the ways we collect, use and disclose your Personal Information. In most cases, we will ask you to indicate your choices at the time we collect your Personal Information. We will also include an "opt-out" link in newsletter or promotional email we send you so that you can inform us that you do not wish to receive such communications from us in the future. If you have created an account or a personal profile on our site, you may be able to update some or all of your privacy choices by changing the settings on your account settings or profile page.
                    </p>
                    <p>
                        <u>Direct marketing</u><br />
                        Before we use your Personal Information for direct marketing purposes (or permit our affiliates to do so), we will offer you the opportunity to choose whether to have your information used in this way. If at any time you wish to be taken off our direct marketing mailing lists, please complete the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form</a>,
                        specifying your request. Once we have the information we need, we will remove you from our mailing lists. Please give us a reasonable amount of time to honor your request.
                    </p>
                    <p>
                        <u>Sharing your personal information.</u>. <br />
                        Please review the section entitled "How does Syngenta share my Personal Information?" to learn how we share your Personal Information with third parties and what choices you have.
                    </p>
                    <p>
                        <strong>How can you exercise your rights in relation to your Personal Information?</strong><br />
                        We strive to maintain a high level of transparency about the data we process. As regards our processing of your Personal Information described in this Statement, you have the following rights:
                    </p>
                    <p>
                        <ul>
                            <li>To confirm Syngenta is processing your Personal Information, to get access to or receive a copy of the Personal Information we may have about you;</li>
                            <li>To require us to rectify or update any inaccurate Personal Information, or complete any incomplete Personal Information;</li>
                            <li>To require us to delete or erase your Personal Information;</li>
                            <li>To restrict our processing of your Personal Information;</li>
                            <li>To require us to transmit certain of your Personal Information to you or to transfer or have the information transferred to another data controller (data portability); and</li>
                            <li>To require that we stop processing your Personal Information for direct marketing purposes. Subject to other legitimate interests which we may be able to rely on (e.g. in the context of legal claims pending or threatened against us), we will then no longer be allowed to process your Personal Information.</li>
                        </ul>
                    </p>
                    <p>
                        If you wish to exercise any of your above rights, please complete the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form</a> ,specifying your request.
                    </p>
                    <p>
                        We will respond to your reasonably specified request as quickly as possible after validating the request.
                    </p>
                    <p>
                        We will without undue delay modify your Personal Information if we agree that it is either inaccurate, out of date, incomplete, irrelevant or misleading, after taking into account the purposes for which it was collected. If we correct it and had previously disclosed it to a third party, we will also notify that third party if required to by law.
                    </p>
                    <p>
                        If we disagree with your request to correct your Personal Information, we will explain the reason for refusal and the ways you can complain.
                    </p>
                    <p>
                        <strong>How long does Syngenta store your Personal Information?</strong><br />
                        We intend to keep your Personal Information accurate and up-to-date. We do not wish to store your Personal Information for any longer than necessary for the relevant purposes in relation to the use of our Website. How long we need to keep your Personal Information depends on the purpose for which we collect it, and therefore there is no standard retention period. Thus, the retention period may vary depending on the circumstances. For other legal reasons, e.g. in the context of legal claims, we may need hold Personal Information beyond the term required to meet the defined purpose for which they were collected in the first place. However, in these cases we will no longer actively process the Personal Information for the originally specified purpose.
                        <br /><br/>Should you wish to obtain more information on where and how long your Personal Information is stored, and on your right of erasure please complete the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form</a>, specifying your request.

                    </p>
                    <p>
                        <strong>How does Syngenta protect Personal Information?</strong><br />
                        We maintain a comprehensive data security program, including commercially reasonable administrative, physical and technological security measures, to protect Personal Information from unauthorized access, unauthorized use, and unauthorized or accidental destruction, modification or disclosure. Although we take commercially reasonable precautions to provide an appropriate level of security, we cannot guarantee the security of Personal Information provided over the internet or stored in our databases.
                    </p>
                    <p>
                        <strong>Links to other Syngenta websites</strong><br />
                        This Site may contain hyperlinks to other websites operated by Syngenta or other Syngenta entities (“Other Syngenta Website(s)”). These Other Syngenta Websites are separate and distinct websites. Each serves a different purpose. Each Other Syngenta Website is therefore governed by its own terms of use and its own privacy statement. If you access Other Syngenta Websites through links on this site, please take a few minutes to review the terms of use and privacy statement posted on each Other Syngenta Website you visit.
                    </p>
                    <p>
                        <strong>Links to other web sites</strong><br />
                        This site may contain links to web sites operated by other companies, including websites operated by our third-party service providers, our affiliates, and other third parties. This Statement does not apply to Personal Information collected on any of these third-party web sites. When you access third-party web sites through a link on this site, please take a few minutes to review the privacy policy posted on that site.
                    </p>
                    <p>
                        <strong>Children</strong><br />
                        This site is not directed nor targeted to children under the age of 13. We do not use this site to knowingly solicit Personal Information from, or market to, children under the age of 13. If you are under the age of 13, do not provide us with any Personal Information. If we learn that someone under 13 has provided Personal Information through one of our sites, we will use reasonable efforts to remove such Personal Information from our databases.
                    </p>
                    <p>
                        <strong>Special notice for visitors who reside outside the United States</strong><br />
                        Syngenta uses web servers and stores data in Switzerland, France, the United States or in other countries. We may transfer your Personal Information to our service providers and others located in Switzerland, France, the United States or other countries for the purposes described in this Statement. Different countries have different privacy laws and requirements, and some provide less legal protection for your personal information than others.
                        <br /><br />
                        Please know, however, that no matter where your Personal Information is collected or processed, if it was collected through this Site, we will ensure that it is protected by the terms of this Statement and any privacy notices or click-through agreements that apply to you.
                        <br />
                        <br />
                        By using this site, you are consenting to the collection, use, and transfer of your Personal Information in or to the United States or any other country in the world.
                    </p>
                    <p>
                        <strong>Governing law & Jurisdiction</strong><br />
                        This Statement is governed under the laws of Delaware without regard to its conflicts of law provisions. The United States District Court for the District of Delaware, Wilmington, Delaware, shall have exclusive jurisdiction for any claims relating to these Statement.
                    </p>
                    <p>
                        <strong>Questions, concerns and complaints concerning our privacy practices</strong><br />
                        Should you have any question or concern on how Syngenta utilizes your Personal Information, you can contact our Global Data Protection Officer: <a href="mailto:data.privacy@syngenta.com">data.privacy@syngenta.com</a>. To exercise any of your privacy rights, please complete the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form</a>, specifying your request.
                        <br /><br />
                        You also have a right to lodge a complaint (including a potential right to appeal decisions made in respect of your exercise of your privacy rights) with the relevant supervisory authority (in particular in the State of your habitual residence, place of work or place of the alleged infringement).
                    </p>

                   <p>Read Google’s privacy policy at <a href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US</a>.</p>
                   
                    <p>
                         <strong>Additional Information for Regional U.S. Consumers (“Notice”)</strong><br />
                          This Notice further explains how we collect, use, and disclose your Personal Information. It also describes how to exercise your rights under the California Consumer Privacy Act (“CCPA”), the Colorado Privacy Act, the Connecticut Act Concerning Personal Data Privacy and Online Monitoring, the Utah Consumer Privacy Act, and the Virginia Consumer Data Protection Act. Syngenta refers to those laws collectively as the “U.S. Privacy Laws”.</br></br>
                          When Syngenta references “Personal Information” in this Notice, that is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked with consumers, directly or indirectly. Personal Information does not include information that cannot be reasonably linked to you.
                    </p>
                     <p>
                         <strong>How Syngenta may collect, use, and disclose Personal Information</strong></br>
                          The chart below shows the categories of Personal Information we may collect through this site, examples of the types of Personal Information in each category; the types of sources from which each category of Personal Information is collected; the business or commercial purpose(s) for which that category of Personal Information is collected as well as the types of third parties with whom that category of Personal Information is disclosed or shared. Syngenta does not disclose your data in exchange for money. This information covers the past 12 months.
                           <table>
                               <tr>
                                  <th>Categories of Personal Information Collected</th>
                                  <th>Categories of Sources from which personal information is collected </th>
                                  <th>Business or commercial purposes for collection and disclosure</th>
                                  <th>Types of third parties with whom Personal Information is shared or disclosed</th>
                                  <th>Intended Data Retention Period </th>
                               </tr>
                                <tr>
                                     <td><strong>Identifiers </strong>(for example, real name, address, email address, account name, user ID, device identifiers, IP address, or other similar identifiers)</td>
                                     <td>
                                         Directly from a consumer through this website or a hosted event or program.</br></br>
                                         From Service Providers, industry outlets or from publicly available information.
                                     </td>
                                     <td>For the purposes identified in the section entitled “How does Syngenta use your Personal Information?”</td>
                                     <td>As described in the section entitled “How does Syngenta share my Personal Information?”</td>
                                     <td>As long as required for the business purpose or as legally required</td>
                                </tr>
                                 <tr>
                                     <td><strong>Customer Records </strong>(for example, customer records containing personal information, such as name, address, telephone number)</td>
                                     <td>
                                         Directly from a consumer through this website or a hosted event or program.</br></br>
                                         From Service Providers, industry outlets or from publicly available information.
                                     </td>
                                     <td>For the purposes identified in the section entitled “How does Syngenta use your Personal Information?”</td>
                                     <td>As described in the section entitled “How does Syngenta share my Personal Information?”</td>
                                     <td>As long as required for the business purpose or as legally required  </td>
                                 </tr>
                                   <tr>
                                       <td><strong>Commercial Information </strong>(for example, records of products, services purchased or considered, and purchase preferences)</td>
                                       <td>
                                           Directly from a consumer through this website or a hosted event or program.</br></br>
                                           From Service Providers, industry outlets or from publicly available information.
                                       </td>
                                       <td>For the purposes identified in the section entititled “How does Syngenta use your Personal Information?”</td>
                                       <td>As described in the section entitled “How does Syngenta share my Personal Information?”</td>
                                       <td>As long as required for the business purpose or as legally required</td>
                                   </tr>
                                   <tr>
                                       <td><strong>Internet Activity Information </strong>(for example, browsing history, search history, and interactions with an Internet website, application or advertisement)</td>
                                       <td>
                                           Directly from a consumer through this website or a hosted event or program.</br></br>
                                           From Service Providers or from publicly available information.
                                       </td>
                                       <td>For the purposes identified in the section entititled “How does Syngenta use your Personal Information?”</td>
                                       <td>As described in the section entitled “How does Syngenta share my Personal Information?”</td>
                                       <td>As long as required for the business purpose or as legally required</td>
                                   </tr>
                                   <tr>
                                       <td><strong>Inferences </strong>(for example, inferences drawn from any of the information identified above to create a profile about a consumer reflecting preferences and behavior)</td>
                                       <td>
                                           Internal</br></br>
                                           From Service Providers
                                       </td>
                                       <td>For the purposes identified in the section entititled “How does Syngenta use your Personal Information?”</td>
                                       <td>As described in the section entitled “How does Syngenta share my Personal Information?”</td>
                                       <td>As long as required for the business purpose or as legally required</td>
                                   </tr>
                           </table>
                     </p>
                    <p>
                        <strong>How can you exercise your rights provided under the U.S. Privacy Laws?  </strong></br></br>
                        Depending on where you live and subject to certain exceptions, you may have some or all of the following rights:
                        <ul>
                            <li><strong>Right to Know: </strong>The right to request that we disclose to you the Personal Information about you that we collect, use, or disclose, and information about our data practices. This includes (i) the right to confirm whether or not we are processing your Personal Information and to access such data; (ii) the right to know what personal information has been collected about you, including the categories of Personal Information, the categories of sources from which the Personal Information is collected, the business or commercial purpose for collecting, selling, or sharing Personal Information, the categories of third parties to whom the Personal Information  is disclosed, and the specific pieces of Personal Information that have been collected about you.</li>
                            <li><strong>Right to Request Correction: </strong>The right to request that we correct inaccurate Personal Information that we maintain about you.</li>
                            <li><strong>Right to Request Deletion: </strong>The right to request that we delete your Personal Information that we have collected from or about you.</li>
                            <li><strong>Right to Opt Out of Targeted Advertising: </strong>The right to opt out of the processing of your Personal Information obtained from your activities on nonaffiliated websites or online applications for the purposes of targeted advertising.</li>
                            <li><strong>Right to Non-Discrimination: </strong>The right not to receive discriminatory treatment for exercising your privacy rights.</li>
                            <li><strong>Right to Data Portability: </strong>The right to have certain of your Personal Information transmitted to you or to transfer or have the information transferred to another data controller. </li>
                        </ul>
                    </p>
                    <div id="privacy-laws">
                        <p>
                            If you are a California resident or otherwise have rights under U.S. Privacy Laws and you would like to: (i) opt out of the sale or sharing of your personal information to or with third parties; (ii) limit the use of your sensitive Personal Information; or (iii) exercise any of your rights pursuant to this Privacy Statement, please complete the <a href="https://privacyportal-ch.onetrust.com/webform/13c48a5e-e500-46e7-8510-ce0e5d999986/451fff32-4e1b-46a9-ae0f-04b05ab3b47b">Individual Privacy Rights Request Self-Service Form </a> and describe your request, or call 1-800-334-9481.
                        </p>
                    </div>
                    <p>
                        <strong><u>Verification Process</u></strong></br>
                        After we receive your request, to ensure the security of the information we store and consumers’ privacy, we will verify that you are appropriately affiliated with the subject of the request, either as the consumer or as an authorized agent or guardian of the consumer.  We may ask you to provide a few pieces of information to confirm your identity in our records.
                    </p>
                    <p>
                        <strong><u>Non-Discrimination</u></strong></br>
                        Please note that a legitimate denial of a request for information, deletion, or to opt-out is not discriminatory, nor is charging a fee for excessive or repetitive consumer requests as permitted by the CCPA.
                    </p>
                    <p>
                        <strong><u>Authorized Agents</u></strong></br>
                        You may designate an authorized agent to exercise your rights under the CCPA on your behalf.</br>
                        Pursuant to the CCPA:
                        <ul>
                            <li>Only a business entity or natural person registered with the California Secretary of State may act as an authorized agent.</li>
                            <li>You must provide the authorized agent written permission to exercise your rights under the CCPA on your behalf.</li>
                            <li>We may deny a request from an authorized agent on your behalf if the authorized agent does not submit proof that he, she, or it has been authorized by you to act on your behalf if we request such proof, as permitted by the CCPA.</li>
                        </ul>
                        Even if you use an authorized agent to exercise your rights under the CCPA on your behalf, pursuant to the CCPA we may still require that you verify your own identity directly to us.

                    </p>
                    <p>
                        <strong><u>Personal Information of Minors</u></strong></br>
                        Our operation of this website, our activity on social media, and our services are not intended for children or minors under the age of 16 years old. Accordingly, we do not knowingly collect, store, share or sell information about minors under the age of 16. If you believe that we have unintentionally received personal information about a minor under the age of 16 years old, please contact us at <a href="mailto:data.privacy@syngenta.com">data.privacy@syngenta.com</a>.
                    </p>
                    <p>
                        <strong><u>Changes</u></strong></br>
                        Syngenta reserves the right to amend this Statement, including the Notice, at our discretion and at any time.
                    </p>
                    <p>
                        <strong><u>Third Parties under the CCPA</u></strong><br/><br/>
                        Listed below are provisions which are required to be in place between a ‘business’ and a ‘third party’ (as such terms are defined in the CCPA) pursuant to the CCPA:
                        <ul>
                            <li>Personal Information is only made available by the business to the third party for the limited and specified purpose(s) set forth within the contract between the business and the third party and requires the third party to use it only for that limited and specified purpose(s).</li>
                            <li>The third party is to comply with applicable sections of the CCPA and its regulations, including--with respect to the personal information that the business makes available to the third party--providing the same level of privacy protection as required of businesses by the CCPA and its regulations. For example, the third party is to comply with a consumer's request to opt-out of sale/sharing forwarded to it by a first-party business and to implement reasonable security procedures and practices appropriate to the nature of the personal information to protect the personal information from unauthorized or illegal access, destruction, use, modification, or disclosure in accordance with the CCPA.</li>
                            <li>The business has the right--with respect to the personal information that the business makes available to the third party--to take reasonable and appropriate steps to ensure that the third party uses it in a manner consistent with the business's obligations under the CCPA and its regulations.</li>
                            <li>The business has the right, upon notice, to take reasonable and appropriate steps to stop and remediate unauthorized use of Personal Information made available to the third party.</li>
                            <li>The third party is to notify the business after it makes a determination that it can no longer meet its obligations under the CCPA and its regulations.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>
</body>
</html>
 `,
            }}
          />
        </div>
      ),
      onOk() {},
    });
  }

  handleagrrementClick() {
    this.setState({ agreeClk: true });
    Modal.info({
      title: 'User Agreement',
      width: '80%', // Adjust the width as needed
      content: (
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: `
    
<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <!-- <link rel="stylesheet" type="text/css" href="../css/reset.css" />-->
    <link rel="stylesheet" type="text/css" href="/css/bootstrap.css" />
    <link rel="stylesheet" type="text/css" href="/css/hf2017.css" />
    <link rel="stylesheet" type="text/css" href="/css/styleHome_new.css" />
    <title>User Agreement And Legal Information</title>
</head>
<body class="no-resp" style="overflow: visible">
    <header id="m-menu">
        <div class="nav-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 col-md-push-10">
                        
                    </div>
                    <div class="col-md-10 col-md-pull-2">
                        <div class="hidden-xs hidden-sm" style="height:80px"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="sectionTitle">
                        <h2>Terms & Conditions</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <p>
                        Syngenta Corporation and its affiliates in the United States and Canada (collectively, "Syngenta") provide the linked website (this "Site") subject to the terms and conditions of this User Agreement (this "Agreement").
                        <br />
                        <br />
                        <strong>Your acceptance of these terms and conditions  </strong><br />
                        Please take a few minutes to carefully review these terms and conditions. By accessing and using this Site you agree to follow and be bound by these terms and conditions. If you do not agree to follow and be bound by these terms and conditions, you may not access, use or download materials from this Site.
                        <br /><br />
                        <strong>These terms and conditions may change</strong><br />
                        Syngenta reserves the right to update or modify these terms and conditions at any time without prior notice. Syngenta will do so by posting an updated or modified version of these terms and conditions on this Site.  Your use of this Site following any such change constitutes your agreement to follow and be bound by the revised terms and conditions.  For this reason, we encourage you to review these terms and conditions every time you use this Site.
                        <br /><br />
                        <strong>Privacy statement </strong><br />
                        Syngenta is committed to respecting the personal privacy of the individuals who use this Site.  The privacy statement posted on this site describes Syngenta’s current policies and practices with regard to the personal information collected by Syngenta through this Site.
                        <br /><br />
                        The privacy statement is part of these terms and conditions.  When you agree to be bound by these terms and conditions, you are consenting to the collection, use and disclosure of your personal information in accordance with the privacy statement on this Site. Please take a few minutes to review this.
                        <br /><br />
                        <strong>Usernames, passwords and security</strong><br />
                        Any username and password for this Site provided to you, or chosen by you, are for the use of you personally, and may not be used by anyone else (including other employees or agents of your company). You and your company are entirely responsible for maintaining the confidentiality of your username and password. Furthermore, you and your company are entirely responsible for all activities that occur on this site using your username and password, whether or not the activities or the use of your password was authorized by you or your company. You or your company must notify Syngenta immediately of any known or suspected unauthorized use of your username and password or any other breach of security.
                        <br /><br />
                        <strong>Updating user information </strong><br />
                        You or your company must notify Syngenta promptly if any of the information that you provided as part of any registration process (the "user information") changes, or if you or your company becomes aware that any of the user information is inaccurate or incomplete.
                        <br /><br />
                        Notwithstanding your or your company's obligation to provide Syngenta with updated user information, Syngenta has the right to rely on the user information that you or your company previously provided until such time as you or your company has provided Syngenta with updated user information. You and your company further agree that Syngenta may provide notices related to these terms and conditions or to this Site by e-mail using the latest e-mail address you or your company has supplied to Syngenta.
                        <br /><br />
                        If you cease to be an employee or authorized agent of your company, or cease to be authorized by your company to use this Site, then your company must promptly notify Syngenta, and you are no longer authorized to use this Site.
                        <br /><br />
                        As between you and your company and Syngenta, Syngenta shall have sole and exclusive ownership of all right, title, and interest in and to this Site, including, without limitation, the design, features, functionality, navigation, content, and "look and feel" of this Site, and all data collected through this Site, including, without limitation, all intellectual property rights associated therewith, such as all copyrights, patents, trademarks, trade secrets, and know-how.
                        <br /><br />

                        <strong>Right to change Site </strong><br />
                        Syngenta reserves the right to alter the content and functionality of this Site in any way, or to limit access to this Site, or to shut down this Site temporarily or permanently, at any time, for any reason, without prior notice, and will not be liable in any way to you or your company for possible consequences of such changes or shut down.
                        <br /><br />
                        <strong>Copyright notice and limited license  </strong><br />
                        Everything you see and hear on this Site (the “Content”), including, for example, all of the text, photographs, illustrations, graphics, audio clips, video clips, and audio-video clips, is copyrighted
                        <br /><br />
                        You are granted a non-exclusive license to the Content subject, however, to the following limitations:
                        <br /><br />
                        You may download, store, print, copy and share Content from this Site, provided you:
                        <br /><br />
                        (1) only use the Content for noncommercial use or to further your business dealings with Syngenta;
                        <br /><br />
                        (2) only publish or post any part of the Content on any other internet site or in any other media in a similar context to that in which it is presented on this Site;
                        <br /><br />
                        (3) attribute the Content to Syngenta;
                        <br /><br />
                        (4) do not modify or alter the Content in any way or delete or modify any copyright or trademark notices or notices of confidentiality.
                        <br /><br />
                        Except as expressly stated above, you may not copy, download, print, publish, display, perform, distribute, transmit, transfer, translate, modify, add to, update, compile, abridge, frame, inline, link or deep-link with regard to any other publicly available site or in any other way transform or adapt all or any part of the Content of this Site without first obtaining written permission from Syngenta.
                        <br /><br />
                        Except as expressly stated above, no right, title or interest in the downloaded Content is transferred to you when you download content from this Site. Except for the limited, non-exclusive license expressly granted above, nothing contained in these terms and conditions and on this Site shall be construed as conferring a license or any other right under any copyright, trademark, patent, or other intellectual property right of Syngenta or any third party.
                        <br /><br />
                        <strong>Trademark notice </strong><br />
                        All of the trademarks, service marks and logos displayed on this Site (the “Trademark(s)”) are registered and unregistered trademarks of Syngenta, one of its affiliates, or third parties who have licensed their trademarks to Syngenta or one of its affiliates.
                        <br /><br />
                        Except as expressly stated in these terms and conditions, you may not reproduce, display or otherwise use any Trademark without first obtaining Syngenta's written permission.
                        <br /><br />
                        <strong>Unsolicited ideas</strong><br />
                        Syngenta welcomes your comments and feedback regarding this Site. All information and materials submitted to Syngenta through this Site, such as any comments, feedback, ideas, questions, designs, data or the like, will be considered non-confidential and non-proprietary. For this reason, we ask that you not send us any information or materials that you do not wish to assign to us, including, without limitation, any confidential information and any original creative materials.
                        <br />
                        <br />
                        By submitting communications and/or materials to Syngenta through this Site, you assign to Syngenta, free of charge, all worldwide rights, title and interest in all copyrights and other intellectual property rights in the information and/or materials you submit to Syngenta. Syngenta will be entitled to use any information and/or materials you submit through this Site, and any ideas, concepts, know-how or techniques contained in any such information and/or materials, for any purpose whatsoever, included but not limited to developing, manufacturing and marketing products using such information or materials without restriction and without compensating you in any way.
                        <br />
                        <br />
                        However, Syngenta will not use any information or materials you submit through this Site in a way that may violate applicable privacy laws.  In particular, Syngenta will not release your name or otherwise publicize the fact that you submitted information or materials to us unless: (a) we obtain your permission to use your name; or (b) we first notify you that the materials or other information you submit to a particular part of this Site will be published or otherwise used with your name on it; or (c) we are required to do so by law.
                        <br /><br />
                        You are responsible for the communications you submit through this Site, including, without limitation, their truthfulness and accuracy.
                        <br /><br />
                        Please note that your submissions (e.g. via our contact form) do not have any legally binding effect on Syngenta. Therefore, invoices, reminders and the like must be exclusively sent to the addresses set forth in the respective contract.
                        <br /><br />
                        <strong>Third-party information </strong><br />
                        Some of the information, articles and other materials available through this Site are provided to Syngenta by third-parties. Wherever practical the source of these third-party materials is identified. These third-party materials are provided for your interest and convenience only. Syngenta does not warrant or represent that these materials are current, accurate, complete or reliable. Syngenta accepts no responsibility for any use to which third party information is put.
                        <br /><br />
                        <strong>Links to other Syngenta websites </strong><br />
                        This Site may contain hyperlinks to other websites operated by Syngenta or other Syngenta entities ("Other Syngenta Website(s)"). These Other Syngenta Websites are separate and distinct websites. Each serves a different purpose. Each Other Syngenta Website is therefore governed by its own terms of use and its own privacy statement. If you access Other Syngenta Websites through links on this Site, please take a few minutes to review the terms of use and privacy statement posted on each Other Syngenta Website you visit.
                        <br /><br />
                        <strong>Links to other web sites</strong><br />
                        This Site may contain hyperlinks to web sites that are not operated by Syngenta. These hyperlinks are provided for your reference and convenience only, and do not imply any endorsement of the material on these third-party web sites or any association with their operators. Syngenta does not control these web sites and is not responsible for their contents. You access and use these web sites solely at your own risk.
                        <br />
                        <br />
                        <strong>Product information </strong><br />
                        Any information contained or referenced in this Site is <strong>solely</strong> provided as an introduction to Syngenta and its products and services. For specific advice and instructions related to our products and services, please contact Syngenta directly. Persons intending to use a crop protection or seed product must read and follow the label accompanying that product and comply with all applicable laws and regulations relating to the use of that product. Before using any crop protection product, be sure that it is registered for use in your country.

                        <br /><br />
                        <strong>Global availability </strong><br />
                        Because different countries around the world have different laws and regulatory requirements, some products, programs or services are available in some countries and not in others. This Site may contain references or cross references to Syngenta products, programs and services that may not be available or announced in your country. These references do not imply that Syngenta intends to announce such products, programs or services in your country. Consult your local Syngenta sales representative or contact Syngenta if you have questions about which products, programs and services may be available to you.
                        <br /><br />
                        <strong>Contact us </strong><br />
                        The “contact us” feature of some Syngenta websites allows you the opportunity to send related questions to our experts online. This feature is provided for your interest and convenience. The usefulness of our response to your question depends on the completeness and the accuracy of the information on which it is based and other factors beyond our control, such as weather. You and your company are solely responsible for evaluating the value and usefulness of our response.
                        <br /><br />
                        Some Syngenta websites will post representative questions and answers that we believe may be of interest to other registered users. Please do not include any confidential, proprietary or personally identifiable information in your question. We reserve the right to edit questions for clarity or length. Please review the section of these terms and conditions regarding submissions before sending us any information.
                        <br /><br />
                        <strong>Not an offer or invitation to invest </strong><br />
                        The information on this Site does not constitute and shall not be deemed as an offer or invitation to invest or otherwise deal in shares or other securities of Syngenta. Nor is any such offer or invitation being made or solicited. Securities’ prices, and income from those securities, may go up or down at any time, and potential investors should be aware that past performance is not necessarily an indication of future performance. Potential investors should seek independent financial advice prior to any investment decision.
                        <br /><br />
                        <strong>Forward-looking statements</strong><br />
                        Our Site may contain forward-looking statements - e.g. statements not of historical fact, including statements about our beliefs and expectations. These statements, which can be identified by terms such as, but not limited to, ‘expect’, 'would', 'will', 'potential', 'plans', 'prospects', 'estimated', 'aiming', 'on track' and similar expressions are based on current plans, estimates and projections, with regard to which Syngenta shall in no way be liable and on which you and your company should not place undue reliance. These statements involve inherent risks and uncertainties, many of which are outside of Syngenta's control. Forward looking statements speak only as of the date they are made and Syngenta undertakes no obligation to update any of them in light of new information or future events.
                        <br /><br />
                        <strong>Disclaimer of warranties </strong><br />
                        This Site is provided on an "as is," "as available" basis, without representations or warranties of any kind. To the fullest extent permitted under applicable law, the Syngenta entities and their respective service providers and licensors disclaim all warranties, express, implied, statutory or otherwise. Without limiting the foregoing, to the fullest extent permitted by law, the Syngenta entities and their respective service providers and licensors hereby disclaim (a) all warranties of merchantability, fitness for a particular purpose, title and non-infringement with respect to this Site and its content and any products or services described on or made available through this Site; (b) warranties relating to delays, interruptions, errors, or omissions in the operation of this Site or any part of this Site; (c) warranties relating to the transmission or delivery of this Site or its availability at any particular time or location; (d) warranties relating to the security of this Site or that the Content of this Site is free of viruses, worms or other code that may manifest contaminating or destructive properties; (e) warranties relating to the use, validity, accuracy, currency, completeness, suitability, reliability, or the results of the use of this Site, including any Content, including product labels, products or services made available through this Site; and (f) warranties relating to websites to which this Site is linked.
                        <br /><br />
                        Certain jurisdictions do not allow disclaimers of certain warranties or limitations on how long an implied warranty lasts, so one or all of the foregoing limitations may not apply to you and/or your company.
                        <br /><br />
                        <strong>Limitation of liability </strong><br />
                        Your and your company's use of this Site, it’s Content, including product labels, and any products, programs or services made available through this Site is at your and your company's sole risk. Under no circumstances shall the Syngenta entities or their respective service providers and licensors, including their respective directors, officers, employees, or agents, be liable for any losses or damages arising out of or in connection with your or your company's access of, use of, or inability to use, this Site, its content, including product labels, or any products, programs or services made available through this site or your or your company's reliance on any information provided through this site. Without limiting the foregoing, the Syngenta entities and their respective service providers and licensors shall not be liable in any way for possible errors or omissions in the content; this applies in particular to any information about products, programs and services supplied by the Syngenta entities. This is a comprehensive limitation of liability that applies to all losses and damages of any kind whatsoever, whether direct or indirect, general, special, incidental, consequential, exemplary or otherwise, including without limitation, interruption of business, loss of or damage to data or goodwill, and loss of revenue or profits.
                        <br /><br />
                        This limitation of liability shall apply to the fullest extent permitted by applicable law, whether the alleged liability is based on contract, negligence, tort, strict liability or any other basis and even if an authorized representative of any of the Syngenta entities has been advised of or should have known of the possibility of such damages, and without regard to the effectiveness of other remedies.
                        <br /><br />
                        You and your company acknowledge and agree that Syngenta could not make this Site and its Content available to you and your company at no charge if their liability were not limited as set forth above.
                        <br /><br />
                        If any of these limitations of liability is found to be invalid or unenforceable for any reason, then the aggregate liability of the Syngenta entities and their respective service providers and licensors under such circumstances for liabilities that otherwise would have been limited shall not exceed one hundred ($100.00) dollars (or the local currency equivalent).
                        <br /><br />
                        Certain jurisdictions do not allow the limitation of certain damages, so some or all of this limitation of liability may not apply to you and/or your company and you and/or your company may have additional rights.
                        <br /><br />
                        <strong>Governing law and jurisdiction </strong><br />
                        These terms and conditions shall be governed by, are subject to, and construed in accordance with, the laws of Delaware without regard to conflict of law principles. The United States District Court for the District of Delaware, Wilmington, Delaware, shall have exclusive jurisdiction for any claims relating to these terms and conditions.
                        <br /><br />
                        <strong>Waiver</strong><br />
                        Our failure at any time to require performance of any provision of these terms and conditions or to exercise any right provided for herein shall not be deemed a waiver of such provision or such right. All waivers must be in writing and signed by the party to be bound. Unless the written waiver contains an express statement to the contrary, no waiver by Syngenta of any breach of any provision of these terms and conditions of any right provided for herein shall be construed as a waiver of any continuing or succeeding breach of such provision, a waiver of the provision itself, or a waiver of any right under these terms and conditions.
                        <br /><br />
                        <strong>Severability</strong><br />
                        If any provision of these terms and conditions is held by a court of competent jurisdiction to be contrary to law, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law and the remaining provisions of these terms and conditions shall remain in full force and effect.
                        <br /><br />
                        <strong>Entire agreement </strong><br />
                        These terms and conditions constitute the entire agreement between you and your company and Syngenta with respect to your and your company's access to and/or use of this Site. These terms and conditions shall not be amended except as expressly set forth herein.
                        <br /><br />
                        <strong>Revision date</strong><br />
                        These terms and conditions were last modified on January 1, 2020.<br/>
                        <br /><br />

                        </td>
                        </tr>
                        <tr>
                            <td align="center" valign="middle" class="content"></td>
                        </tr>
                        </table>
                    </p>
                </div>
            </div>
        </div>
    </div>
</body>
</html>


`,
            }}
          />
        </div>
      ),
      onOk() {},
    });
  }

  handlePolicyLink() {
    this.setState({ policyLinkClicked: true });
  }

  openCookiePolicy() {
    this.setState({ showCookie: true });
  }

  closeCookiePolicy() {
    this.setState({ showCookie: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.props.logoutUser();
    this.props.history.push(APP_GROWER_LOGIN);
  }

  async componentDidMount() {
    const handler = (e) => this.setState({ smallScreen: e.matches });
    const mediaWatcher = window.matchMedia('(max-width: 500px)');
    if (mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener('change', handler);
    } else {
      mediaWatcher.addListener(handler);
    }
    if (isEmpty(this.props.session)) {
      const session = await this.props.oktaAuth.getUser();
      if (session) {
        const accessToken = this.props.oktaAuth.getAccessToken();
        session.accessToken = accessToken;
        const { email } = session;
        addUserIdentification(email);
        this.props.setSession(session);
      } else {
        this.props.logoutUser();
      }
    } else {
      this.loadUserDetail();
    }
  }

  loadUserDetail = () => {
    this.props.loadUserDetail(
      this.props.sessionEmail,
      this.loadUserType || getUserTypeFromUrl(),
      window.localStorage.getItem('seed-advisor-id') || '',
      window.localStorage.getItem('zd-partnership-uuid')
    );
    this.loadUserType = undefined;
  };

  componentDidUpdate(prevProps) {
    const { user, menuItems, session, history, location } = this.props;

    if (
      user?.is_agreements_agreed !== undefined &&
      !user?.is_agreements_agreed
    ) {
      this.showNtc = true;
    }
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (
      userDetails?.is_agreements_agreed !== undefined &&
      !userDetails?.is_agreements_agreed
    ) {
      this.showNtc = true;
    } else {
      this.showNtc = false;
    }

    if (
      userDetails?.userType === 'GROWER' &&
      userDetails?.grower_seed_advisors?.length === 0
    ) {
      this.showNtc = false;
    }

    // if (
    //   userDetails?.userType === 'GROWER' &&
    //   userDetails?.grower_seed_advisors?.length > 0 &&
    //   userDetails.step !== undefined &&
    //   userDetails.step === applicationSteps.CONFIRMATION
    // ) {
    //   this.showNtc = true;
    // }

    if (
      session &&
      ((!user && !prevProps.session) || (prevProps.user && !user))
    ) {
      this.loadUserDetail();
    } else if (
      user &&
      menuItems.length > 0 &&
      APP_HOME === get(history, 'location.pathname', APP_HOME)
    ) {
      if (user.isProfileComplete) {
        history.push(menuItems[0].navLink);
      } else {
        let targetUrl;
        switch (user.userType) {
          case USER_TYPES.ADMIN:
            targetUrl = ADMIN_BULLETIN;
            break;
          case USER_TYPES.SEED_ADVISOR:
            targetUrl = SA_PROFILE;
            break;
          default:
            targetUrl = DASHBOARD;
            break;
        }
        history.push(targetUrl);
      }
    } else if (
      user &&
      menuItems.length &&
      location.pathname.indexOf(this.checkAccessURL()) === -1
    ) {
      history.push(menuItems[0].navLink);
    }
    dataLayer.push({
      event: 'PageView',
      path: '/landing',
      userType: user ? user.userType : 'session',
    });
  }

  getUserProfileURL = () => {
    const { user } = this.props;
    const returnURL =
      USER_TYPES.GROWER === user.userType
        ? GET_GROWER_PROFILE()
        : USER_TYPES.SALES_REPRESENTATIVE === user.userType
        ? SR_PROFILE
        : USER_TYPES.CUST_SUPPORT_ADMIN === user.userType
        ? CSA_PROFILE
        : USER_TYPES.SEED_ADVISOR === user.userType
        ? SA_PROFILE
        : CSA_PROFILE;
    return returnURL;
  };
  navigateToProfile = () => {
    const { history } = this.props;
    const targetUrl = this.getUserProfileURL();
    history.push(targetUrl);
  };

  onPortalSwitch = (targetRole) => {
    const { setUserProfile, history } = this.props;
    let targetUrl;
    switch (targetRole) {
      case USER_TYPES.SEED_ADVISOR:
        this.loadUserType = USER_TYPES.SEED_ADVISOR;
        targetUrl = SA_DASHBOARD;
        break;
      case USER_TYPES.SALES_REPRESENTATIVE:
        this.loadUserType = USER_TYPES.SALES_REPRESENTATIVE;
        targetUrl = SR_DASHBOARD;
        break;
      case USER_TYPES.ADMIN:
        this.loadUserType = USER_TYPES.ADMIN;
        targetUrl = ADMIN_BULLETIN;
        break;
      case USER_TYPES.GROWER:
        this.loadUserType = USER_TYPES.GROWER;
        targetUrl = DASHBOARD;
        break;
      case USER_TYPES.CUST_SUPPORT_ADMIN:
        this.loadUserType = USER_TYPES.CUST_SUPPORT_ADMIN;
        targetUrl = CSA_DASHBOARD;
        break;
      default:
        this.loadUserType = undefined;
    }
    setUserProfile(undefined);
    history.push(targetUrl);
  };

  onNotificationsClick = () => {
    const { history, user } = this.props;
    if (user.userType === USER_TYPES.GROWER) {
      history.push('/grower/notifications');
    } else if (user.userType === USER_TYPES.SEED_ADVISOR) {
      history.push('/seed-advisor/notifications');
    } else if (user.userType === USER_TYPES.CUST_SUPPORT_ADMIN) {
      history.push('/customer-support/notifications');
    } else if (user.userType === USER_TYPES.SALES_REPRESENTATIVE) {
      history.push('/sales-representative/notifications');
    }
  };

  checkAccessURL() {
    const { user } = this.props;
    if (user.userType === USER_TYPES.GROWER) {
      return 'grower';
    } else if (user.userType === USER_TYPES.SEED_ADVISOR) {
      return 'seed-advisor';
    } else if (user.userType === USER_TYPES.CUST_SUPPORT_ADMIN) {
      return 'customer-support';
    } else if (user.userType === USER_TYPES.SALES_REPRESENTATIVE) {
      return 'sales-representative';
    } else if (user.userType === USER_TYPES.ADMIN) {
      return 'administrator';
    }
  }

  toggleCollapsed = () =>
    this.setState({ isSiderCollapsed: !this.state.isSiderCollapsed });

  toggleHeaderMenu = () =>
    this.setState({ isHeaderMenuVisible: !this.state.isHeaderMenuVisible });

  getDualRoleMenuItem = () => {
    const { user, messages, commonMessages } = this.props;
    if (user && user.userType && user.roles && user.roles.length > 1) {
      const roleMsg = {
        [USER_TYPES.GROWER]: messages.menu.grower_portal,
        [USER_TYPES.SEED_ADVISOR]: messages.menu.seed_advisor_portal,
        [USER_TYPES.SALES_REPRESENTATIVE]:
          messages.menu.sales_representative_portal,
        [USER_TYPES.ADMIN]: messages.menu.admin_portal,
        [USER_TYPES.CUST_SUPPORT_ADMIN]: messages.menu.cas_portal,
      };
      return (
        <Menu.SubMenu
          key="switch-to-menu"
          icon={<TeamOutlined />}
          title={commonMessages.switch_to}
        >
          {user.roles
            .filter((role) => role !== user.userType)
            .map((role) => {
              return (
                <Menu.Item
                  key={`role-${role}`}
                  onClick={() => this.onPortalSwitch(role)}
                >
                  {roleMsg[role]}
                </Menu.Item>
              );
            })}
        </Menu.SubMenu>
      );
    }
  };

  profileNavLink = (userType) => {
    switch (userType) {
      case USER_TYPES.GROWER:
        return GET_GROWER_PROFILE();
      case USER_TYPES.SEED_ADVISOR:
        return SA_PROFILE;
      case USER_TYPES.CUST_SUPPORT_ADMIN:
        return CSA_PROFILE;
      default:
        return '';
    }
  };

  userMenu = () => {
    const { user, logoutUser, messages, sessionEmail: email } = this.props;
    const showEmail = !(trim(user.first_name) || trim(user.last_name));
    const initial = upperCase(
      showEmail
        ? `${email[0]}`
        : `${user.first_name[0]}${user.last_name ? user.last_name[0] : ''}`
    );

    return (
      <span>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="profile"
                icon={<UserOutlined />}
                onClick={this.navigateToProfile}
              >
                <NavLink to="">{messages.menu.profile}</NavLink>
              </Menu.Item>
              {this.getDualRoleMenuItem()}
              <Menu.Item
                key="sign-out"
                icon={<LogoutOutlined />}
                onClick={logoutUser}
              >
                {messages.menu.sign_out}
              </Menu.Item>
            </Menu>
          }
        >
          <Button className="user-avtar-cnt">
            <Row gutter={6}>
              <Col>
                <Avatar>{initial}</Avatar>
              </Col>
              <Col>
                <p className="user-name">
                  {showEmail
                    ? email
                    : startCase(
                        lowerCase(
                          `${user.first_name} ${
                            user.last_name ? user.last_name : ''
                          }`
                        )
                      )}
                </p>
                <p className="user-type">
                  {this.getUserTypeValue(user.userType)}
                </p>
              </Col>
            </Row>
          </Button>
        </Dropdown>
      </span>
    );
  };

  headerMenu = () => {
    const { location, menuItems } = this.props;
    return (
      <Dropdown
        className="header-menu"
        visible={this.state.isHeaderMenuVisible}
        overlay={
          <Menus
            menuItems={menuItems}
            currPath={get(location, 'pathname', '')}
            toggleMenu={this.toggleHeaderMenu}
          />
        }
        trigger={['click']}
      >
        <Avatar
          size={48}
          icon={<BarsOutlined />}
          onClick={this.toggleHeaderMenu}
        />
      </Dropdown>
    );
  };

  footerContent = () => {
    const { messages } = this.props;
    return (
      <Row>
        <Col span={15}>
          <Row gutter={16}>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/copyright.aspx"
              >
                {new Date().getFullYear()}©{' ' + messages.syngenta}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/useragreement.html"
              >
                {messages.user_agreement}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.online_privacy_policy}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/cookiepolicy.html"
              >
                {messages.cookie_policy}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/SMSpolicy.html"
              >
                {messages.sms_t_c}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.dont_sell}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.limit_sensitive_info}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
          </Row>
        </Col>
        <Col span={9}>
          <Row gutter={{ xs: 8, sm: 16 }}>
            <Col>{messages.ga_support}</Col>
            <Col>
              <a href="mailto: support@goldenadvantage.com">
                {messages.support_email}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>{messages.support_phone}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  getUserTypeValue = (userType) => {
    const { smallScreen } = this.state;
    if (smallScreen) {
      const personaShortNameMapping = {
        ADMINISTRATOR: 'ADMIN',
        CUSTOMER_SUPPORT: 'CAS',
        GROWER: 'GROWER',
        SALES_REPRESENTATIVE: 'SALES REP',
        SEED_ADVISOR: 'SA',
      };
      return personaShortNameMapping[userType];
    }
    return userType.replace('_', ' ');
  };

  render() {
    const { messages, user, location, menuItems, session, sessionEmail } =
      this.props;
    const { isSiderCollapsed } = this.state;
    const notificationCount =
      user &&
      user.notifications &&
      user.notifications.filter((x) => x.is_read_completed !== true);
    const { Header } = Layout;
    const { Footer } = Layout;
    const timeout = getUserIdleTimeoutDuration(sessionEmail);
    const isInActiveGrowerWithNoCreditHistory =
      checkInactiveGrowerWithNoCreditHistory(user);
    const showNtc = verifyNotice();
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (userDetails !== null) userDetails['showNoticeModal'] = showNtc;

    if (isInActiveGrowerWithNoCreditHistory)
      return (
        <Modal
          visible={isInActiveGrowerWithNoCreditHistory}
          transitionName=""
          onOk={() => {
            this.props.logoutUser();
          }}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Result
            status="warning"
            title={messages.inactive_grower_error_title}
            subTitle={
              <div>
                {messages.inactive_grower_error_subtitle}
                <a href="mailto:support@goldenadvantage.com">
                  support@goldenadvantage.com
                </a>
              </div>
            }
          />
        </Modal>
      );

    return (
      <React.Fragment>
        <Spin spinning={!(session && session.accessToken) || !user}>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={timeout}
            onAction={this.onAction}
            onIdle={this.onIdle}
            onActive={this.onActive}
            debounce={500}
          />

          <Layout className="main-container">
            <IdleTimeOutModal
              showModal={this.state.showModal}
              messages={messages}
              handleClose={this.handleClose}
              handleLogout={this.handleLogout}
            />

            <NoticeModal
              showModal={this.showNtc}
              policyClk={this.state.policyClk}
              agreeClk={this.state.agreeClk}
              messages={messages}
              handleClose={this.handleNoticeClose}
              handlepolicyClick={this.handlepolicyClick}
              handleagrrementClick={this.handleagrrementClick}
              handleLogout={this.handleLogout}
            />

            <Header className="app-header" mode="inline">
              {user && (
                <Row className="action-container">
                  <Col className="ant-col-xs-4">
                    <img src={logo} className="logo" alt="logo" />
                    {this.headerMenu()}
                  </Col>
                  <Col className="headerInfoAlignment  ant-col-xs-20">
                    <span>
                      {getEnvironmentValue(SALES_YEAR_CONFIG) && (
                        <span>
                          <FinancialYearSelector />
                        </span>
                      )}
                    </span>
                    <div
                      className="notif-container"
                      onClick={() => this.onNotificationsClick()}
                    >
                      <img
                        src={notification}
                        alt="Notification Icon"
                        className="mail"
                      />
                      <span
                        className={
                          notificationCount?.length > 0
                            ? 'message-notification badgecolor'
                            : ''
                        }
                        data-badge="2"
                      >
                        {notificationCount?.length > 0
                          ? notificationCount?.length
                          : ''}
                      </span>
                    </div>
                    {this.userMenu()}
                  </Col>
                </Row>
              )}
            </Header>

            <div
              className={classNames('toggle-collapse-ele-cnt', {
                'toggle-element-expanded-margin': !isSiderCollapsed,
                'toggle-element-collapsed-margin': isSiderCollapsed,
              })}
            >
              <Tooltip
                placement="right"
                title={isSiderCollapsed ? messages.expand : messages.collapse}
              >
                <Avatar
                  className="toggle-collapse-ele"
                  icon={isSiderCollapsed ? <RightOutlined /> : <LeftOutlined />}
                  onClick={this.toggleCollapsed}
                />
              </Tooltip>
            </div>
            <SideMenu
              className="side-menu"
              menu={messages.menu}
              currPath={get(location, 'pathname', '')}
              isSiderCollapsed={isSiderCollapsed}
              menuItems={menuItems}
            />

            <Layout className="content-layout">
              <ContentBody isSiderCollapsed={isSiderCollapsed} user={user} />
              <Footer className="footer">{this.footerContent()}</Footer>
            </Layout>
          </Layout>
        </Spin>
        <CookieConsent />
        <CookieInfo
          showModal={this.state.showCookie}
          closeCookieDetail={this.closeCookiePolicy}
          messages={messages}
        />
      </React.Fragment>
    );
  }
}
function checkInactiveGrowerWithNoCreditHistory(user) {
  if (user) {
    const isGrowerUserType =
      !!user.userType && user.userType.toLowerCase() === 'grower';
    const isInactiveGrower = isGrowerUserType && isGrowerInactive(user);
    return !user.grower_credit_histories?.length && isInactiveGrower;
  }
  return false;
}

function verifyNotice() {
  try {
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (
      userDetails?.is_agreements_agreed !== undefined &&
      !userDetails?.is_agreements_agreed
    ) {
      return true;
    }
  } catch (e) {
    console.log('error');
  }
  return false;
}
export default Landing;
